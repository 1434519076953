.ant-table-thead > tr > th {
  font-size: 12pt;
  padding: 2px;
  border: solid 1px black;

}

.ant-table-tbody > tr > td {
  height: 35px;
  padding: 2px;
  font-size: 12pt;
  border: solid 1px black;

}
